<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option :value="0">新建</Option>
            <Option :value="1">已处理</Option>
            <Option :value="2">关闭</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="daterange"
          placement="bottom-end"
          placeholder="创建日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :columns="columns" :data="violationList" :loading="isLoading"></Table>
    <Pager :total="violationListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryViolation, deleteAdmin, createAdmin, changeViolationStatus } from "@/api"
import { toHumanDate, apiUtil } from "@/util"
import "@/components/tableUtil"

export default {
  name: "ItemViolationQueryPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "商品举报管理", link: this.$route.path }],
      createdAtRange: ["", ""],
      queryForm: { status: -1 },
      page: 1,
      isLoading: false,
      violationList: [],
      violationListCount: 0,
      dialogDisplay: false,
      columns: [
        { title: "标识", key: "id", width: 50, maxWidth: 80 },
        { title: "商品名称", key: "itemTitle", width: 100, maxWidth: 120 },
        { title: "举报原因", key: "reason", width: 120, maxWidth: 150 },
        { title: "邮箱", key: "email", width: 150, maxWidth: 200 },
        { title: "电话", key: "phone" },
        { title: "类型", key: "type", width: 80 },
        {
          title: "状态",
          render: (h, params) => {
            return h("StatusHelper", {
              props: { value: params.row.status, on: 0, off: 1 },
              on: {
                change: async (value) => {
                  await apiUtil.call(this, async () => await changeViolationStatus(params.row.id, value))
                },
              },
            })
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          minWidth: 80,
          render: (h, params) => h("div", toHumanDate(params.row.createdAt)),
        },
        {
          title: "操作",
          width: 140,

        },
      ],
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    createdAtRange: {
      async handler() {
        await this.reload()
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.isLoading = true
        const status = this.queryForm.status != -1 ? this.queryForm.status : null
        let startTime = null,
          endTime = null
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryViolation(this.page, startTime, endTime, status)
        this.violationList = items
        this.violationListCount = count
        this.isLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async delete(id) {
      try {
        await deleteAdmin(id)
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    async onNewSubmit() {
      try {
        await createAdmin(this.$refs.adminEditor.formData)
        this.$Message.success("新建成功")
        await this.reload()
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
